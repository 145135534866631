import { CobranzasAxios, COMPANY } from './config'

export const getBillingCompany = async () => {
  const res = await CobranzasAxios.get(`/fact/information/${COMPANY}`)
  return res.data
}

export const postBillingCompany = async (data) => {
  const res = await CobranzasAxios.post(`/fact/register/${COMPANY}`, data)
  return res.data
}

// type = 01 || 03
// export const getVouchers = async (type) => {
//   const res = await CobranzasAxios.get(`/payment/facturation-pay/${type}/${COMPANY}`, {
//     headers: { Authorization: UserService.token() },
//   });
//   return res.data;
// };

export const getCreditNote = async () => {
  const res = await CobranzasAxios.get(`/creditnote/${COMPANY}`)
  return res.data
}

export const generateCreditNote = async (voucherId, data) => {
  const res = await CobranzasAxios.post(`/creditnote/${voucherId}/${COMPANY}`, data)
  return res.data
}

export const sendEmailVoucher = async (data) => {
  const res = await CobranzasAxios.post(`/fact/send-email/${COMPANY}`, data)
  return res.data
}

// typeVoucher => 01=factura; 03=boleta
export const getPendingVouchers = async (typeVoucher) => {
  const res = await CobranzasAxios.get(`/payment/pending/${typeVoucher}/${COMPANY}`)
  return res.data
}

export const sendSunat = async (idOperation) => {
  const res = await CobranzasAxios.get(`/fact/sendSunat/${idOperation}/${COMPANY}`)
  return res.data
}

export const sendMassiveBallots = async (ids) => {
  const res = await CobranzasAxios.post(`/fact/massiveSunat/${COMPANY}`, ids)
  return res.data
}

// OBTENER ENVÍOS MASIVOS

export const getMassiveComprobants = async () => {
  const res = await CobranzasAxios.get(`/massive/${COMPANY}`)
  return res.data
}

// REVISAR ESTADO DEL COMPROBANTE

export const checkStatusComprobants = async (id) => {
  const res = await CobranzasAxios.get(`/massive/status-ticket/${id}/${COMPANY}`)
  return res.data
}

// VOLVER A ENVIAR EL MASIVO

export const resendMassiveToSunat = async (id) => {
  const res = await CobranzasAxios.get(`/fact/massiveSunat/${id}/${COMPANY}`)
  return res.data
}

// ANULAR BEFORE SEND TO SUNAT

export const anularBoleta = async (id) => {
  const res = await CobranzasAxios.put(`/voucher/anulated/${id}`, {})
  return res.data
}

export const anularManual = async (id) => {
  const res = await CobranzasAxios.put(`/payment/anulated-manual/${id}`, {})
  return res.data
}

export const getVouchersById = async (id) => {
  const res = await CobranzasAxios.get(`/payment/status/id/${id}`)
  return res.data
}

export const getCreditNoteById = async (id) => {
  const res = await CobranzasAxios.get(`/creditnote/id/${id}`)
  return res.data
}

// PAGOS
export const getPaymentsDebts = async () => {
  const res = await CobranzasAxios.post(`/payment/debts/${COMPANY}`, {})
  return res.data
}
